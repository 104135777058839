import classes from './FizjoDetails.module.css'

function FizjoDetails(props) {
    return (<div className={classes.fizjoDetailContainer}>
        <div className={classes.fizjoHeader}>
            <img className={classes.fizjoImage} src={props.fizjoDetails.image_url} alt={props.name} />
            <div className={classes.fizjoInfo}>
                <h1 className={classes.fizjoName}>{props.fizjoDetails.name}</h1>
                <p className={classes.fizjoSpecialization}>{props.fizjoDetails.specialization}</p>
            </div>
        </div>
        <div className={classes.fizjoBody}>
            <p className={classes.fizjoDescription}>{props.fizjoDetails.description}</p>
            <div className={classes.fizjoAddress}>
                <h2>Adres</h2>
                <p>{props.fizjoDetails.address_line1}</p>
                {props.fizjoDetails.address_line2 && <p>{props.fizjoDetails.address_line2}</p>}
                <p>{props.fizjoDetails.city}, {props.fizjoDetails.postal_code}</p>
            </div>
        </div>
    </div>);
}

export default FizjoDetails;