import { useState, useEffect } from "react";

import FizjoList from "../components/fizjos/FizjoList";


function AllFizjos() {
    const apiHost = process.env.REACT_APP_API_HOST;

    const [areFizjosLoading, setAreFizjosLoading] = useState(true);
    const [areFiltersLoading, setAreFiltersLoading] = useState(true);
    const [loadedFizjos, setLoadedFizjos] = useState([]);
    // filters that came from the backend
    const [loadedFilters, setLoadedFilters] = useState({ cities: [], specializations: [] });
    // filters selected by a user
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedSpecializations, setSelectedSpecializations] = useState([]);

    useEffect(() => {
        fetch(`${apiHost}/filters`
        ).then((response) =>
            response.json()
        ).then((data) => {
            setAreFiltersLoading(false)
            setLoadedFilters(data);
        });
    }, [apiHost]);

    useEffect(() => {
        let url = `${apiHost}/fizjos?`;

        if (selectedCities.length > 0) {
            url += `cities=${selectedCities.join(",")}&`;
        }
        if (selectedSpecializations.length > 0) {
            url += `specializations=${selectedSpecializations.join(",")}&`;
        }

        if (url.endsWith('&') || url.endsWith('?')) {
            url = url.slice(0, -1);
        }

        fetch(url
        ).then((response) =>
            response.json()
        ).then((data) => {
            setAreFizjosLoading(false);
            setLoadedFizjos(data);
        });
    }, [selectedCities, selectedSpecializations, apiHost]);

    const handleCityChange = (event) => {
        const { value, checked } = event.target;
        setSelectedCities((prev) =>
            checked ? [...prev, value] : prev.filter((city) => city !== value)
        );
    };
    const handleSpecializationChange = (event) => {
        const { value, checked } = event.target;
        setSelectedSpecializations((prev) =>
            checked ? [...prev, value] : prev.filter((spec) => spec !== value)
        );
    };

    if (areFizjosLoading || areFiltersLoading) {
        return <section>
            <p>Loading...</p>
        </section>
    }

    return (
        <div style={{ display: "flex" }}>
            <aside style={{ marginRight: "20px" }}>
                <h1>Filtry</h1>
                <h2>Miasto</h2>
                {loadedFilters.cities.map((city) => (
                    <div key={city}>
                        <input
                            type="checkbox"
                            id={city}
                            value={city}
                            onChange={handleCityChange}
                        />
                        <label htmlFor={city}>{city}</label>
                    </div>
                ))}
                <h2>Specjalizacja</h2>
                {loadedFilters.specializations.map((specialization) => (
                    <div key={specialization}>
                        <input
                            type="checkbox"
                            id={specialization}
                            value={specialization}
                            onChange={handleSpecializationChange}
                        />
                        <label htmlFor={specialization}>{specialization}</label>
                    </div>
                ))}
            </aside>
            <section>
                <h1>All Fizjos</h1>
                <FizjoList fizjos={loadedFizjos} />
            </section>
        </div>);
}


export default AllFizjos;