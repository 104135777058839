import { useRef } from "react";

import Card from "../ui/Card";
import classes from "./NewFizjoForm.module.css";


function NewFizjoForm(props) {

    const nameInputRef = useRef();
    const specializationInputRef = useRef();
    const imageInputRef = useRef();

    function submitHandler(event) {
        //default is to reload the page
        event.preventDefault();

        const nameValue = nameInputRef.current.value;
        const specializationValue = specializationInputRef.current.value;
        const imageValue = imageInputRef.current.value;

        const fizjoData = {
            name: nameValue,
            specialization: specializationValue,
            image_url: imageValue
        }

        props.onAddFizjo(fizjoData);
    }

    return (
        <Card>
            <form className={classes.form} onSubmit={submitHandler}>
                <div className={classes.control}>
                    <label htmlFor='name'>Imię i naziwsko</label>
                    <input type='text' id='name' required ref={nameInputRef} />
                </div>
                <div className={classes.control}>
                    <label htmlFor='specialization'>Specjalizacja</label>
                    <input type='text' id='specialization' required ref={specializationInputRef} />
                </div>
                <div className={classes.control}>
                    <label htmlFor='image'>Zdjęcie profilowe (URL)</label>
                    <input type='url' id='image' required ref={imageInputRef} />
                </div>
                <div className={classes.actions}>
                    <button>Dodaj fizjo</button>
                </div>
            </form>
        </Card>

    )
}

export default NewFizjoForm;