import { Routes, Route } from 'react-router-dom';

import AllFizjos from './pages/AllFizjos';
import Favorites from './pages/Favorites';
import NewFizjo from './pages/NewFizjo';
import Layout from './components/layout/Layout';
import FizjoPage from './pages/FizjoPage';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<AllFizjos />} />
        <Route path="/fizjo/:slug" element={<FizjoPage />} />
        <Route path="/new-fizjo" element={<NewFizjo />} />
        <Route path="/favorites" element={<Favorites />} />
      </Routes>
    </Layout>
  );
}

export default App;
