
import { useNavigate } from 'react-router-dom';
import NewFizjoForm from "../components/fizjos/NewFizjoForm";

function NewFizjo() {
    const navigate = useNavigate();


    function addFizjoHandler(fizjoData) {
        console.log(fizjoData);
        const api_host = process.env.REACT_APP_API_HOST;

        fetch(`${api_host}/fizjo`, {
            method: 'POST',
            body: JSON.stringify(fizjoData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            navigate('/');
        });
    }

    return (<section>
        <h1>Add new fizjo</h1>
        <NewFizjoForm onAddFizjo={addFizjoHandler} />
    </section>)
}


export default NewFizjo;