import Card from '../ui/Card';
import classes from './FizjoItem.module.css';
import { useNavigate } from 'react-router-dom';

function FizjoItem(props) {
    const navigate = useNavigate();

    function handleClickDetails() {
        console.log(props);
        navigate("/fizjo/" + props.slug);
    }

    return <li className={classes.item}>
        <Card>
            <div className={classes.cardBlock}>
                <div className={`${classes.image}`}>
                    <img src={props.image_url} alt={props.title} />
                </div>
                <div className={`${classes.content}`}>
                    <h3 className={classes.name}>{props.name}</h3>
                    <h4 className={classes.specialization}>{props.specialization}</h4>
                </div>
                <div className={`${classes.actions}`}>
                    <button onClick={handleClickDetails}>Szczegóły</button>
                </div>
            </div>
        </Card>
    </li>
}

export default FizjoItem;