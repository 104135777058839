import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FizjoDetails from '../components/fizjos/FizjoDetails';


function FizjoPage() {
    const { slug } = useParams();
    const [fizjoDetails, setFizjoDetails] = useState(null);

    useEffect(() => {
        const api_host = process.env.REACT_APP_API_HOST;

        fetch(`${api_host}/fizjo/${slug}`)
            .then(response => response.json())
            .then(data => setFizjoDetails(data))
            .catch(error => console.error('Error fetching item details:', error));
    }, [slug]);

    if (!fizjoDetails) return <div className="loading">Loading...</div>;

    return (
        <FizjoDetails fizjoDetails={fizjoDetails} />
    );
};

export default FizjoPage;
