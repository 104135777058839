import FizjoItem from './FizjoItem';
import classes from './FizjoList.module.css';

function FizjoList(props) {
    return <ul className={classes.list}>
        {props.fizjos.map(fizjo => (
            <FizjoItem
                key={fizjo.id}
                image_url={fizjo.image_url}
                name={fizjo.name}
                specialization={fizjo.specialization}
                slug={fizjo.slug}
            />
        ))}
    </ul>
}

export default FizjoList;